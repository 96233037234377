var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-layout',{attrs:{"d-flex":"","align-center":"","justify-space-between":""}},[_c('v-autocomplete',{staticClass:"usernames",attrs:{"items":_vm.localItems,"clearable":"","placeholder":"Enter username"},model:{value:(_vm.tableOptions.username),callback:function ($$v) {_vm.$set(_vm.tableOptions, "username", $$v)},expression:"tableOptions.username"}}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-8"},[_c('date-picker',{attrs:{"dense":"","label":"From"},model:{value:(_vm.tableOptions.date_from),callback:function ($$v) {_vm.$set(_vm.tableOptions, "date_from", $$v)},expression:"tableOptions.date_from"}})],1),_c('date-picker',{attrs:{"dense":"","label":"To"},model:{value:(_vm.tableOptions.date_to),callback:function ($$v) {_vm.$set(_vm.tableOptions, "date_to", $$v)},expression:"tableOptions.date_to"}})],1)],1),_c('v-data-table',{staticClass:"mt-7",attrs:{"headers":_vm.accountHeaders,"hide-default-footer":"","show-expand":"","loading":_vm.loading,"items":_vm.filteredItems,"item-key":"username"},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"target":"_blank","href":("https://twitter.com/" + value)}},[_vm._v(_vm._s(value))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":"12"}},[_c('v-expansion-panels',{attrs:{"accordion":""}},[_c('v-expansion-panel',{attrs:{"active-class":"no-shadow"}},[_c('v-expansion-panel-header',[_c('span',{staticClass:"expansion-title"},[_vm._v("Subscriptions")])]),_c('v-expansion-panel-content',[(item.subscriptions.length===0)?_c('div',[_vm._v(" No data ")]):_vm._e(),(item.subscriptions.length>0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.subscriptions),function(el){return _c('div',{key:el,staticClass:"ma-2"},[_c('a',{attrs:{"target":"_blank","href":("https://twitter.com/" + el)}},[_vm._v(_vm._s(el))])])}),0):_vm._e()])],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[_vm._v("Unsubsctiptions")])]),_c('v-expansion-panel-content',[(item.unsubscriptions.length===0)?_c('div',[_vm._v(" No data ")]):_vm._e(),(item.unsubscriptions.length>0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.unsubscriptions),function(el){return _c('div',{key:el,staticClass:"ma-2"},[_c('a',{attrs:{"target":"_blank","href":("https://twitter.com/" + el)}},[_vm._v(_vm._s(el))])])}),0):_vm._e()])],1)],1)],1)]}},{key:"item.total_subscriptions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"\n          d-flex\n          align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm.icons.mdiArrowUp))]),_vm._v(_vm._s(item.total_subscriptions))],1),_c('span',{staticClass:"\n          d-flex\n          align-center\n          ml-3"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"error"}},[_vm._v(_vm._s(_vm.icons.mdiArrowDown))]),_vm._v(_vm._s(item.total_unsubscriptions))],1)])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }