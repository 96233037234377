<template>
  <v-card
    height="100%"
  >
    <v-card-text>
      <v-layout
        d-flex
        align-center
        justify-space-between
      >
        <v-autocomplete
          v-model="tableOptions.username"
          :items="localItems"
          class="usernames"
          clearable
          placeholder="Enter username"
        ></v-autocomplete>
        <div class="d-flex align-center">
          <div
            class="mr-8"
          >
            <date-picker
              v-model="tableOptions.date_from"
              dense
              label="From"
            />
          </div>
          <date-picker
            v-model="tableOptions.date_to"
            dense
            label="To"
          />
        </div>
      </v-layout>
      <v-data-table
        :headers="accountHeaders"
        class="mt-7"
        hide-default-footer
        show-expand
        :loading="loading"
        :items="filteredItems"
        item-key="username"
      >
        <template v-slot:item.username="{value}">
          <a
            target="_blank"
            :href="`https://twitter.com/${value}`"
          >{{ value }}</a>
        </template>
        <template v-slot:expanded-item="{ headers,item }">
          <td
            class="pa-4"
            colspan="12"
          >
            <v-expansion-panels accordion>
              <v-expansion-panel active-class="no-shadow">
                <v-expansion-panel-header>
                  <span class="expansion-title">Subscriptions</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-if="item.subscriptions.length===0"
                  >
                    No data
                  </div>
                  <div
                    v-if="item.subscriptions.length>0"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="el in item.subscriptions"
                      :key="el"
                      class="ma-2"
                    >
                      <a
                        target="_blank"
                        :href="`https://twitter.com/${el}`"
                      >{{ el }}</a>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>Unsubsctiptions</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-if="item.unsubscriptions.length===0"
                  >
                    No data
                  </div>
                  <div
                    v-if="item.unsubscriptions.length>0"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="el in item.unsubscriptions"
                      :key="el"
                      class="ma-2"
                    >
                      <a
                        target="_blank"
                        :href="`https://twitter.com/${el}`"
                      >{{ el }}</a>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </template>
        <template v-slot:item.total_subscriptions="{item}">
          <div class="d-flex">
            <span
              class="
            d-flex
            align-center"
            ><v-icon
              class="mr-1"
              small
              color="success"
            >{{ icons.mdiArrowUp }}</v-icon>{{ item.total_subscriptions }}</span>
            <span
              class="
            d-flex
            align-center
            ml-3"
            ><v-icon
              class="mr-1"
              small
              color="error"
            >{{ icons.mdiArrowDown }}</v-icon>{{ item.total_unsubscriptions }}</span>
          </div>
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          >
          </TableFooter>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiFilter, mdiArrowUp, mdiArrowDown } from '@mdi/js'
import TwitterApi from '@core/api/twitter'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import TableFooter from '@/components/TableFooter.vue'
import DatePicker from '@/components/DatePicker.vue'

function dateFormat(date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

export default {
  components: { DatePicker, TableFooter },
  data() {
    const prevDate = new Date()
    prevDate.setMonth(prevDate.getMonth() - 1)

    return {
      tableOptions: {
        date_to: dateFormat(new Date()),
        date_from: dateFormat(prevDate),
        username: '',
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.itemsPerPage ? +this.$route.query.itemsPerPage : localStorage.getItem('itemsPerPage') || 50,
      },
      pages: [50, 100, 200],
      total: 50,
      isSubMode: true,
      icons: {
        mdiFilter,
        mdiArrowUp,
        mdiArrowDown,
      },
      loading: true,
      data: [],
      accountHeaders: [
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Subscriptions',
          value: 'total_subscriptions',
          sortable: true,
          filterable: true,
        },
      ],
    }
  },

  computed: {
    localItems() {
      return this.data.map(el => el.username)
    },
    filteredItems() {
      return this.tableOptions.username && this.tableOptions.username.length > 0
        ? this.data.filter(el => el.username === this.tableOptions.username)
        : this.data
    },
  },

  watch: {
    tableOptions: {
      handler(value) {
        if (this.$route.query.date_to !== this.tableOptions.date_to || this.$route.query.date_from !== this.tableOptions.date_from) {
          this.$router.replace({ query: { date_to: value.date_to, date_from: value.date_from } }).catch(() => {})
        }
        if (value.username && value.username.length > 0) {
          if (this.$route.query.username !== value.username) this.$router.replace({ query: { ...this.$route.query, username: value.username } }).catch(() => {})
        } else {
          this.$router.replace({ query: { ...omit(this.$route.query, ['username']) } }).catch(() => {})
        }
        const query = pick(this.tableOptions, ['page', 'date_from', 'date_to'])
        query.limit = value.itemsPerPage

        this.getSubscribers(query)
      },
      deep: true,
    },

  },

  mounted() {
    if (this.$route.query.username) {
      this.tableOptions.username = this.$route.query.username
    }
    if (this.$route.query.date_to) {
      this.tableOptions.date_to = this.$route.query.date_to
      this.tableOptions.date_from = this.$route.query.date_from
    } else {
      this.$router.replace({ query: { date_to: this.tableOptions.date_to, date_from: this.tableOptions.date_from } }).catch(() => {})
    }
    const query = pick(this.tableOptions, ['page', 'date_from', 'date_to'])
    query.limit = this.tableOptions.itemsPerPage

    this.getSubscribers(query)
  },
  methods: {
    async getSubscribers(query) {
      try {
        this.loading = true
        const { data } = await TwitterApi.TrackableUsers.getSubscriptions(query)
        this.data = data.items
        this.total = data.total
      } catch (err) {
        this.$message.error(err)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .badge {
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  height: 20px;
  letter-spacing: 0;
  min-width: 20px;
  padding: 4px 6px;
  pointer-events: auto;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
.usernames {
  max-width: 320px;
}
.v-expansion-panel:before,
.v-application.theme--dark .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}
.expansion-title {
  font-size: 0.875rem;
}
</style>
